import { Button, Divider, Drawer, Form, Input, Radio } from "antd";
import { useForm } from "antd/lib/form/Form";
import React from "react";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { colors } from "../../../Utils/temas";
const { Group } = Radio;

export const DrawerFiltersConfig = ({ visible, onClose, limpar, onFinish }) => {
  const [form] = useForm();

  const onClear = () => {
    limpar();
    form.resetFields();
  };

  return (
    <Drawer visible={visible} onClose={onClose} title="Filtros Config. Grupo">
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="grupo_descricao"
          label="Descrição:"
          style={{ fontWeight: "500" }}
        >
          <Input />
        </Form.Item>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="grupo_em_teste"
            label="Grupo em teste:"
            style={{ fontWeight: "500" }}
          >
            <Group>
              <Radio value={true}>Sim</Radio>
              <Radio value={false}>Não</Radio>
            </Group>
          </Form.Item>

          <Form.Item
            name="parametro_em_teste"
            label="Paramêtro em teste:"
            style={{ fontWeight: "500" }}
          >
            <Group>
              <Radio value={true}>Sim</Radio>
              <Radio value={false}>Não</Radio>
            </Group>
          </Form.Item>
        </div>

        <Form.Item
          name="parametro_nome"
          label="Parâmetro nome:"
          style={{ fontWeight: "500" }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="parametro_secao"
          label="Parâmetro seção:"
          style={{ fontWeight: "500" }}
        >
          <Input />
        </Form.Item>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            height: "68%",
          }}
        >
          <Divider />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              style={{
                background: colors.secondary,
                color: "white",
                fontWeight: "500",
              }}
              className="botao"
              icon={<SearchOutlined />}
              htmlType="submit"
            >
              Confirmar
            </Button>
            <Button icon={<CloseOutlined />} onClick={onClear}>
              Limpar
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};
