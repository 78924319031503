import { UserTockem } from "../../Utils/autenticacao";
import { urlBase } from "../../Constante/Url";
import axios from "axios";

function getHeader(params) {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
}

async function getListaConfig(page, pageSize, params) {
  let skip = (page - 1) * pageSize;
  const url = `${urlBase}config/?limit=${pageSize}&offset=${skip}`;

  const config = getHeader(params);

  const response = await axios.get(url, config);
  return response;
}

async function newGetListaConfig(params) {
  const url = `${urlBase}config/buscar-parametros/`;
  const config = getHeader(params);

  const response = await axios.get(url, config);
  return response;
}

async function getListaConfigGrupo(params) {
  const url = `${urlBase}config-grupo/buscar-config-grupo/`;

  const config = getHeader(params);

  const response = await axios.get(url, config);
  return response;
}

async function getListaConfigGrupoNovo(params) {
  const url = `${urlBase}config-grupo/buscar-config-grupo-novo/`;

  const config = getHeader(params);

  const response = await axios.get(url, config);
  return response;
}

async function CadastrarConfig(data, id) {
  const url = id ? `${urlBase}config/${id}/` : `${urlBase}config/`;
  const config = getHeader();

  let response;
  if (id) {
    response = await axios.put(url, data, config);
  } else {
    response = await axios.post(url, data, config);
  }
  return response;
}

async function getConfig(id) {
  const url = `${urlBase}config/${id}/`;
  const config = getHeader();

  const response = await axios.get(url, config);
  return response;
}

async function ExcluirConfig(id) {
  const url = `${urlBase}config/${id}/`;
  const config = getHeader();
  const response = await axios.delete(url, config);
  return response;
}

const putConfig = async (id, data) => {
  const url = `${urlBase}config/${id}/`;
  const config = getHeader();
  const response = await axios.put(url, data, config);
  return response;
};

export const newConfigRequest = {
  getConfig,
  putConfig,
  ExcluirConfig,
  getListaConfig,
  CadastrarConfig,
  newGetListaConfig,
  getListaConfigGrupo,
  getListaConfigGrupoNovo
};
