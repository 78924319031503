import { Button, Modal, Result } from "antd";
import React, { useState } from "react";
import { requestsZAPI } from "../../../request";
import { ModalErrorCancel } from "../error";
import { colors } from "../../../../../Utils/temas";
import { useUserContext } from "../../../../../contexts/usersContext";

export const ModalCancelInstance = ({
  onCancel,
  token,
  id,
  visible,
  onConfirm,
}) => {
  const { currentUser } = useUserContext();

  const [loading, setLoading] = useState(false);
  const [openError, setOpenError] = useState(false);

  const cancelInstance = async () => {
    setLoading(true);
    try {
      const response = await requestsZAPI.cancelInstance(id, token);
      if (response.status === 200) {
        onCancel();
        onConfirm();
        setLoading(false);
      }
    } catch (error) {
      setOpenError(true);
      onCancel();
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        footer={
          currentUser.is_support
            ? null
            : [
                <Button onClick={onCancel}>Cancelar</Button>,
                <Button
                  loading={loading}
                  onClick={cancelInstance}
                  style={{
                    background: colors.secondary,
                    color: "white",
                    fontWeight: "500",
                  }}
                >
                  Confirmar
                </Button>,
              ]
        }
      >
        {currentUser.is_support ? (
          <Result
            status={"500"}
            title="Sem permissão"
            subTitle="Opa, você não tem permissão para realizar esta ação."
            extra={
              <Button
                type="primary"
                style={{ fontWeight: "500" }}
                onClick={onCancel}
              >
                Voltar
              </Button>
            }
          />
        ) : (
          <text style={{ fontSize: 20, fontWeight: "700" }}>
            Gostaria de cancelar esta instância?
          </text>
        )}
      </Modal>

      <ModalErrorCancel
        onCancel={() => setOpenError(false)}
        visible={openError}
      />
    </>
  );
};
