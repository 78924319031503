import { Button, Divider, Form, Input, Modal, Result } from "antd";
import React, { useState } from "react";
import { requestsZAPI } from "../../../request";
import { useForm } from "antd/lib/form/Form";
import { ModalError } from "../error";
import { useUserContext } from "../../../../../contexts/usersContext";

export const ModalCreateInstance = ({ visible, onCancel, onConfirm }) => {
  const { currentUser } = useUserContext();

  const [form] = useForm();

  const [openError, setOpenError] = useState(false);

  const createInstance = async (data) => {
    try {
      const response = await requestsZAPI.postInstance(data);
      if (response.status === 200) {
        const { data } = response;
        onCancel();
        form.resetFields();
        onConfirm({ id: data.id });
      }
    } catch (error) {
      onCancel();
      setOpenError(true);
      form.resetFields();
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => {
          onCancel();
          form.resetFields();
        }}
        footer={null}
        width={600}
      >
        {currentUser.is_support ? (
          <Result
            status={"500"}
            title="Sem permissão"
            subTitle="Opa, você não tem permissão para realizar esta ação."
            extra={
              <Button
                type="primary"
                style={{ fontWeight: "500" }}
                onClick={onCancel}
              >
                Voltar
              </Button>
            }
          />
        ) : (
          <>
            <text style={{ fontSize: 20, fontWeight: "700" }}>
              Deseja criar uma instância?
            </text>

            <div style={{ marginTop: 8 }}>
              <Form
                layout="vertical"
                form={form}
                onFinish={(value) => createInstance(value)}
              >
                <Form.Item
                  rules={[
                    { required: true, message: "Este campo é obrigatório." },
                  ]}
                  label="Nome:"
                  style={{ fontWeight: "500" }}
                  name="name"
                >
                  <Input placeholder="Digite o nome" />
                </Form.Item>

                <Divider />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "40%",
                    }}
                  >
                    <Button
                      onClick={() => {
                        onCancel();
                        form.resetFields();
                      }}
                      style={{ fontWeight: "500" }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      htmlType="submit"
                      type="primary"
                      style={{ fontWeight: "500" }}
                      disabled={currentUser.is_support}
                    >
                      Confirmar
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </>
        )}
      </Modal>

      <ModalError onCancel={() => setOpenError(false)} visible={openError} />
    </>
  );
};
